/* Home */

#intro-section {
  height: 100vh;
  background: rgb(33, 48, 74);
}

#intro-section .intro-wrapper {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#intro-section .names {
  color: #fbf7ee;
  font-family: "CaslonGraphique";
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 50px;
}

.name {
  display: inline-block;
  line-height: 5.5em;
  letter-spacing: 0.2em;
}

.names .firstname {
  font-size: 140px;
}

.names .lastname {
  font-size: 40px;
}

.right {
  text-align: right;
}

.names .amp {
  font-size: 40px;
  display: inline-flex;
  width: 100px;
  justify-content: center;
  margin-top: 45px;
  color: #3c5c70;
  margin-left: 10px;
  margin-right: 6px;
}

.names .amp:before {
  content: "";
  width: 3px;
  height: 65px;
  background: #3c5c70;
  position: relative;
  bottom: 65px;
  left: 40px;
  transform: rotate(25deg);
}

.names .amp:after {
  content: "";
  width: 3px;
  height: 65px;
  background: #3c5c70;
  position: relative;
  top: 50px;
  right: 52px;
  transform: rotate(25deg);
}

#intro-section .skyline {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 15%;
}

.skyline img {
  width: 70%;
}

#intro-section button {
  display: initial;
}

#intro-section .chevron {
  text-align: center;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce-7;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  width: 80%;
  margin: 0 auto;
}

#intro-section button {
  border: 0;
  background: none;
  cursor: pointer;
}

@keyframes bounce-7 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-15px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-2px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

#intro-section .chevron img {
  width: 40px;
}

@media only screen and (max-width: 1200px) {
  #intro-section .skyline {
    left: -5px;
  }

  .skyline img {
    width: 100%;
    overflow: hidden;
  }

  .name {
    line-height: 3.5em;
  }

  .names .firstname {
    font-size: 75px;
  }

  .names .lastname {
    font-size: 25px;
  }

  .names .amp {
    font-size: 30px;
    width: 80px;
    margin-top: 30px;
  }

  .names .amp:before {
    height: 40px;
    bottom: 40px;
    left: 30px;
    width: 2px;
  }

  .names .amp:after {
    height: 40px;
    top: 38px;
    right: 32px;
    width: 2px;
  }

  #intro-section .chevron img {
    width: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .name {
    line-height: 1.8em;
  }

  .names .firstname {
    font-size: 30px;
  }

  .names .lastname {
    font-size: 13px;
  }

  .names .amp {
    font-size: 20px;
    width: 40px;
    margin-top: 15px;
  }

  .names .amp:before {
    height: 20px;
    bottom: 20px;
    left: 20px;
  }

  .names .amp:after {
    height: 20px;
    top: 28px;
    right: 22px;
  }
}
