/* Pics */

#pics-section {
  height: 100vh;
  display: flex;
  align-content: center;
}

.hero {
  margin: 0 auto;
  width: 100%;
  background-image: url("../assets/hero.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-btn {
  width: 330px;
  height: 60px;
  padding: 10px 25px;
  border: 3px solid #fff;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  margin-top: 6%;
}

.btn-3 {
  line-height: 50px;
  padding: 0;
}

.btn-3:hover {
  background: transparent;
}

.btn-3 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.btn-3:before,
.btn-3:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: #fff;
  transition: all 0.3s ease;
}

.btn-3:before {
  height: 0%;
  width: 2px;
}

.btn-3:after {
  width: 0%;
  height: 2px;
}

.btn-3:hover:before {
  height: 100%;
}

.btn-3:hover:after {
  width: 100%;
}

.btn-3 span:before,
.btn-3 span:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: #fff;
  transition: all 0.3s ease;
}

.btn-3 span:before {
  width: 2px;
  height: 0%;
}

.btn-3 span:after {
  width: 0%;
  height: 2px;
}

.btn-3 span:hover:before {
  height: 100%;
}

.btn-3 span:hover:after {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  #pics-section {
    height: 80vh;
  }
}
