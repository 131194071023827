/* Global */

/* 
Blue: #2A3949
Beige: #fbf7ee
Red: #6c0e23
*/

@font-face {
  font-family: CaslonGraphique;
  src: url("./assets/fonts/CaslonGraphiqueEF.ttf");
}

@font-face {
  font-family: EngraversGothic;
  src: url("./assets/fonts/EngraversGothic.otf");
}

html {
  margin: 0;
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 100%;
}

body {
  width: 100%;
  margin: 0;
  font-family: "EngraversGothic", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./assets/texture.svg");
  background-color: #fbf7ee;
  background-position: top center;
  background-size: 40%;
}

h1 {
  font-family: "CaslonGraphique";
  font-size: 95px;
  color: #2a3949;
}

h2 {
  font-family: "CaslonGraphique";
  margin: 0;
  line-height: 0.95em;
  color: #2a3949;
}

a {
  color: #6c0e23;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

#home-section,
#where-section,
#schedule-section,
#stay-section,
#rsvp-section,
#registry-section,
#faq-section {
  text-align: center;
  padding: 20px;
  padding-left: 120px;
}

.border {
  border: 1px solid #000000;
}

.copyright {
  margin: 35px;
  font-size: 15px;
}

.swiper-button-disabled {
  color: grey !important;
}

.swiper-pagination-bullet-active {
  background-color: #6c0e23 !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: #6c0e23 !important;
}

@media only screen and (max-width: 1200px) {
  h1 {
    font-size: 80px;
  }

  body {
    background-size: 70%;
  }
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 55px;
  }

  #home-section,
  #where-section,
  #schedule-section,
  #stay-section,
  #rsvp-section,
  #registry-section,
  #faq-section {
    padding: 15px;
    padding-left: 10px;
  }
}
