/* Home */

#home-section {
  padding-top: 100px;
  height: 100%;
  display: flex;
  align-content: center;
}

#home-section .pics {
  width: 40%;
  display: inline-block;
  align-self: center;
}

#home-section .slide img {
  height: auto;
  width: 89%;
  object-fit: cover;
  border-radius: 5px;
}

#home-section .info {
  margin-left: 3%;
  width: 55%;
  font-size: 70px;
  line-height: 1.2em;
  text-align: center;
  align-self: center;
}

#home-section .info p {
  display: inline;
}

#home-section .info h2 {
  font-size: 80px;
}

@media only screen and (max-width: 1200px) {
  #home-section {
    padding-top: 80px;
  }

  #home-section .pics {
    width: 45%;
  }

  #home-section .info {
    margin-left: 0;
    font-size: 50px;
    width: 50%;
  }

  #home-section .info h2 {
    font-size: 60px;
  }

  #home-section .slide img {
    height: 500px;
    width: 300px;
    object-position: 50%;
  }
}

@media only screen and (max-width: 768px) {
  #home-section {
    flex-direction: column;
    padding-bottom: 50px;
  }

  #home-section .pics {
    width: 100%;
  }

  #home-section .info {
    margin-top: 50px;
    font-size: 35px;
    width: 100%;
  }

  #home-section .info h2 {
    font-size: 45px;
  }

  #home-section .slide img {
    height: 450px;
    width: 100%;
    object-position: 50%;
  }
}
